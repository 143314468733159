export const environment = {
  title: 'Sakura Admin',
  service: 'admin',
  production: false,
  baseUrl: 'https://dev.api.admin.lookin.lobsterlab.io',
  gqlUrl: 'https://dev.gateway.lookin.lobsterlab.io',
  sakuraUrl: 'https://dev.hrm.lookin.lobsterlab.io',
  publicUrl: '',
  downloadUrl: 'api/pwa/download?url=',
  apiUrl: '',
};
