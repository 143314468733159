import {Component, Injector} from '@angular/core';
import { AbstarctCommonPage } from '@/app/core/abstracts/common-page';
import { environment } from '@/environments/environment';
import {LookinSDK} from 'lookin-sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstarctCommonPage {
  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.initApp();
  }

  async initApp() {
    this.appService.setTitle(environment.title);

    if (!(window.location.pathname.split('/').includes('authorization') || window.location.pathname.split('/').includes('onboarding'))) {
      console.log('23', window.location.pathname.split('/'))
      this.userModel.startUrl = window.location.pathname;
    }

    LookinSDK.initialize({
      url: environment.baseUrl,
      gqlUrl: environment.gqlUrl,
      skipLogin: true,
    }).then(async () => {
      console.log({SDK: LookinSDK});

      this.checkSamlParams();

      this.userModel.makeLogin((isAuth) => {
        this.appService.isLoading = false;
        this.cdr.markForCheck();
      });
      // LookinSDK.registerOnShowAlert((message, theme) => {
      //   console.log({message, theme})
      // })
    })
  }


  checkSamlParams(): void {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('SAMLRequest') && urlParams.has('RelayState')) {
      urlParams.forEach((value, name) => {
        this.appService.setStorage(name, value, true)
      })
    } else {
      this.appService.setStorage('SAMLRequest', null, true);
      this.appService.setStorage('RelayState', null, true);
    }
  }

  get isAdmin(): boolean {
    return environment.service === 'admin';
  }

  menuIsVisble() {
    return this.userModel.user.selectedOrganization?.id
  }

  pageIsInAppContainer() {
    const currentRoute = this.router.config.find(route => route.path === this.router.url.substring(1));
    return currentRoute ? (currentRoute as any).isInAppContainer : true;
  }
}
