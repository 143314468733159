import {Injectable, Injector, inject} from '@angular/core';
import {SakuraModelService} from '@/app/core/services/sakura-model.service';
import {HttpClient} from '@angular/common/http';
import {AppService} from '@/app/services/app.service';
import {ApiResponse} from '@/app/entities/api-response/api-response.entity';
import {environment} from '@/environments/environment';
import {Router} from '@angular/router';
import {LookinSDK, Organization, User, WorkerAccessToOrganizationStatus} from 'lookin-sdk';

@Injectable({
  providedIn: 'root'
})
export class UserModelService extends SakuraModelService {
  currentLocation: string;
  startUrl: string;
  router = inject(Router);
  user: User;

  constructor(injector: Injector) {
    super(injector);
  }

  get token(): string {
    if (typeof window['authToken']!=='undefined') {
      return window['authToken'];
    }

    return environment.service === 'admin' ? localStorage.getItem('token') ?? '' : 'GPs0w0pmPrYXe_l8K4X2ZV6WGF8oLAZ8';
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  async makeLogin(callback: (status: boolean) => void = () => {}) {
    if (!this.token) {
      this.goToAuth();
      callback(false);
      return;
    }

    if (environment.service === 'admin') {
      try {
        const user = await LookinSDK.authService?.start();

        if (!user || !(user instanceof User)) {
          this.token = '';
          this.goToAuth();
          callback(false);
          return;
        }

        this.user = user;

        this.goRedirect(callback);
      } catch (e) {
        this.token = '';
        this.goToAuth();
        callback(false);
      }
    } else {
      callback(true);
    }
  }

  async goRedirect(callback: (status: boolean) => void = () => {}) {
    if (!this.user.selectedOrganization) {
      this.router.navigate(['/onboarding'], {replaceUrl: true});
    } else {
      if (await this.checkSaml()) {
        return;
      }

      const organizationDomain = this.getOrganizationDomain();

      if (organizationDomain) {
        const organizations = await LookinSDK.workerAccessToOrganizationModel.getItems(this.user.id.toString(), WorkerAccessToOrganizationStatus.active);

        if (organizations.status) {
          const currentOrganization = organizations.data.find((i) => i.organization.domain === organizationDomain);

          if (currentOrganization) {
            if (currentOrganization.organization.id !== this.user.selectedOrganization.id) {
              this.changeOrganization(currentOrganization.organization)
            }
          } else {
            this.changeOrganization(organizations.data[0].organization)
          }
        }
      }

      if (this.startUrl) {
        this.router.navigate([this.startUrl], {replaceUrl: true});
      } else {
        this.router.navigate(['/organization'], {replaceUrl: true});
      }

      this.startUrl = null;
    }

    callback(true);
  }

  async checkSaml() {
    let status = false;
    const SAMLRequest = this.appService.getStorage('SAMLRequest', null, true);
    const RelayState = this.appService.getStorage('RelayState', null, true);

    if (SAMLRequest && RelayState) {
      await LookinSDK.authService.samlLogin(SAMLRequest, RelayState).then(res => {
        if (res && res.status) {
          const result = res.result;
          const form = document.createElement('form');
          form.action = result.destination;
          form.method = 'POST';
          form.style.display = 'none';
  
          for (const key in result) {
            if (result.hasOwnProperty(key)) {
              const input = document.createElement('input');
              input.type = 'hidden';
              input.name = key;
              input.value = result[key];
              form.appendChild(input);
            }
          }
  
          document.body.appendChild(form);
          form.submit();

          status = true
        } else {
          status = false
        }
      })

      return status
    }
  }
  
  async changeOrganization(organization: Organization) {
    const organizationDomain = this.getOrganizationDomain();

    await this.user.changeOrganization(organization.id);
    if (organizationDomain) {
      window.location.href = window.location.href.replace(organizationDomain, organization.domain)
    } else {
      window.location.href = window.location.protocol + '//' + organization.domain + '.' + window.location.host + window.location.pathname
    }
  }

  goToAuth() {
    const isAuth = window.location.pathname.split('/')?.includes('authorization');
    if (isAuth && !this.token) {
      return;
    }
    if (environment.service === 'admin') {
      this.router.navigate(['/authorization/'], {replaceUrl: true});
    } else {
      window.location.href = '/user-management/auth/login';
    }
  }

  login(emailOrPhone: string, password: string, rememberMe: boolean = false, callback: (isSuccess: boolean) => void) {
    this.post({
      operationName: 'Login',
      query: `mutation Login{login(email:"${emailOrPhone}", password:"${password}")}`
    }, {}, 'graphql', (res) => {
      if (res.status) {
        this.token = res.result.data.login.access_token;
      }
      callback(res.status);
    })
  }

  logout() {
    this.user = null;
    localStorage.removeItem('token');
    this.router.navigate(['/authorization'], {replaceUrl: true});
  }

  isLogin(): boolean {
    return !!this.token;
  }

  getStartData(callback: (answer: ApiResponse) => void) {
    this.post({
      operationName: 'StartData',
      query: 'query StartData { me { id name surname selectedOrganization { id title workers { id } } selectedWorker { id } } organizations(first: 10, where: {HAS: {relation: "workers"}}) { paginatorInfo { total } data { id title workers { id name userId } } } }'
    }, {}, 'graphql', (answer: ApiResponse) => {
      callback(answer);
    });
  }

  getOrganizationDomain(): string {
    const baseUrl = environment.baseUrl.replace('.api', '');
    const url = window.location.href;

    const parsedUrl = new URL(url);
    const parsedBaseUrl = new URL(baseUrl);

    if (parsedUrl.hostname.endsWith(parsedBaseUrl.hostname)) {
      const domainParts = parsedUrl.hostname.split('.').slice(0, -parsedBaseUrl.hostname.split('.').length);

      return domainParts.join('.');
    } else {
      return null;
    }
  }
}
